@import "../core/colours";

.dash-container {
  background-color: $c8;
  flex-grow: 1;
  border-top: 1px solid $c4;
}

.dash-widgets {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dash-widgets-nums-row {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: minmax(0, 1fr);
  }
}

.dash-widget-subtitle {
  color: $c3;
  font-size: 1rem;
  margin-top: 0.25rem;
}

.dash-widget-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 2rem 0;
}

// Individual widget in the layout
div.dash-widget {
  background-color: $c1;
  padding: 1.5rem;
  border-radius: 10px;

  h2 {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }

  // Specific locations for each widget
  &.drug_stats {
    grid-area: drug_stats;
  }

  &.absences {
    grid-area: absences;
  }

  &.compliance {
    grid-area: compliance;
  }
}

div.widget-user-count-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  margin-top: 1.5rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .widget-user-count {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "icon count"
      "label label";

    .icon {
      grid-area: icon;
    }

    .count {
      grid-area: count;
      font-size: 2.5rem;
      font-weight: bold;
      margin-left: 1.5rem;
    }

    .label {
      grid-area: label;
      margin-top: 0.75rem;
      color: $c3;
      font-size: 1rem;
    }
  }

  .widget-user-count-icon {
    border-radius: 999px;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.total {
      color: #1f908b;
      background-color: #cefffc;
    }

    &.staff {
      color: #8a901f;
      background-color: #fffdce;
    }

    &.duty {
      color: #f97400;
      background-color: #ffe2ce;
    }

    &.admin {
      color: #8e1fb2;
      background-color: #f3ceff;
    }

    &.super {
      color: #268507;
      background-color: #D0FFE3;
    }
  }
}

.user-count-by-location {
  .user-count-by-location-count {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: right;
  }
}

.user-count-by-grade {
  .user-count-by-grade-name {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }

  .user-count-by-grade-count {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: right;
  }
}

.names-values-widget {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  align-items: center;

  .horiz-line {
    height: 1px;
    background-color: $c3;
    margin: 0 2rem 0 2rem;
  }
}

.perc-items-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .perc-items-list-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .perc-items-list-row-icon {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 999px;
    }

  .perc-items-list-row-name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .perc-items-list-row-value {
    font-weight: 500;
  }

  .perc-items-list-row-desc {
    color: $c3;
    font-size: 0.875rem;
  }
}